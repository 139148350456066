import $ from 'jquery'
import kdzDeleteRow from './deleteRow'
import kdzSubmit from './forms'
import * as message from "./message";

function initSortable () {
  //$(".sortable").sortable("destroy")
  $(".sortable").sortable({
    revert: true,
    connectWith: ".calendar-colum-item",
    start: function (event, ui) {
      $(ui.item).removeClass("ui-helper");
    },
    stop: function (event, ui) {
      $(ui.item).addClass("ui-helper");
      var _aktItem = $(ui.item)
      var _parent = _aktItem.closest('.calendar-colum-item')
      var modul = _aktItem.attr('data-modul')
      var data

      if (_parent.hasClass('is--projekt-planung')) {
        if (modul === 'Fahrzeug') {
          data = {
            uxPROID_FK: _parent.attr('data-projekt'),
            uxID: _aktItem.attr('data-uxid'),
            uxModul: _aktItem.attr('data-modul'),
            uxContentID: _aktItem.attr('data-proid'),
            uxDay: _parent.attr('data-time'),
            modus: 'setPlan',
            modusType: 'is--projekt',
            modulPfad: 'plugins/mod_planung/mitarbeiter/'
          }
        } else if (modul === 'Werkzeuge') {
          data = {
            uxPROID_FK: _parent.attr('data-projekt'),
            uxID: _aktItem.attr('data-uxid'),
            uxModul: _aktItem.attr('data-modul'),
            uxContentID: _aktItem.attr('data-proid'),
            uxDay: _parent.attr('data-time'),
            modus: 'setPlan',
            modusType: 'is--projekt',
            modulPfad: 'plugins/mod_planung/mitarbeiter/'
          }
        }
        else if (modul === 'Projekt') {
          data = {
            uxPROID_FK: _parent.attr('data-projekt'),
            uxID: _aktItem.attr('data-uxid'),
            uxModul: _aktItem.attr('data-modul'),
            uxUSERID_FK: _aktItem.attr('data-proid'),
            uxDay: _parent.attr('data-time'),
            modus: 'setPlan',
            modusType: 'is--projekt',
            modulPfad: 'plugins/mod_planung/mitarbeiter/'
          }
        }

      } else {
        if (modul === 'Projekt') {
          var data = {
            uxPROID_FK: _aktItem.attr('data-proid'),
            uxID: _aktItem.attr('data-uxid'),
            uxModul: _aktItem.attr('data-modul'),
            uxUSERID_FK: _parent.attr('data-user'),
            uxDay: _parent.attr('data-time'),
            modus: 'setPlan',
            modusType: 'is--mitarbeiter',
            modulPfad: 'plugins/mod_planung/mitarbeiter/'
          }
        } else if (modul === 'Termin') {
          var data = {
            uxContentID: _aktItem.attr('data-proid'),
            uxID: _aktItem.attr('data-uxid'),
            uxModul: _aktItem.attr('data-modul'),
            uxUSERID_FK: _parent.attr('data-user'),
            uxDay: _parent.attr('data-time'),
            modus: 'setPlan',
            modusType: 'is--mitarbeiter',
            modulPfad: 'plugins/mod_planung/mitarbeiter/'
          }
        }else if (modul === 'Fahrzeug') {
          var data = {
            uxUSERID_FK: _parent.attr('data-user'),
            uxID: _aktItem.attr('data-uxid'),
            uxModul: _aktItem.attr('data-modul'),
            uxContentID: _aktItem.attr('data-proid'),
            uxDay: _parent.attr('data-time'),
            modus: 'setPlanMitarbeiter',
            modusType: 'is--mitarbeiter',
            modulPfad: 'plugins/mod_planung/mitarbeiter/'
          }
        } else if (modul === 'Werkzeuge') {
          var data = {
            uxUSERID_FK: _parent.attr('data-user'),
            uxID: _aktItem.attr('data-uxid'),
            uxModul: _aktItem.attr('data-modul'),
            uxContentID: _aktItem.attr('data-proid'),
            uxDay: _parent.attr('data-time'),
            modus: 'setPlanMitarbeiter',
            modusType: 'is--mitarbeiter',
            modulPfad: 'plugins/mod_planung/mitarbeiter/'
          }
        }

      }

      $.ajax({
        url: 'ajax.php',
        type: 'POST',
        dataType: 'json',
        data: data,
        success: function (dataBack) {
          if(dataBack.blocked === true) {
            if (dataBack.modus === 'new') {
              _aktItem.remove()
            } else {
              _aktItem.detach().appendTo("ul[data-time='" + dataBack.data.uxDay + "'][data-projekt='" + dataBack.data.uxPROID_FK + "']")
            }
            message.message('Das Element würde wird an diesem Tag schon verwendet', 'danger')
          } else if(dataBack.eror === true) {
            message.message(dataBack.message, 'danger')
            if (dataBack.modus === 'new') {
              _aktItem.remove()
            } else {
              _aktItem.detach().appendTo("ul[data-time='" + dataBack.data.uxDay + "'][data-projekt='" + dataBack.data.uxPROID_FK + "']")
            }
          } else {
            if (dataBack.modus === 'new') {
              _aktItem.html(dataBack.html)
              _aktItem.attr('data-uxid', dataBack.contentID)
              kdzDeleteRow({
                el: '.deletePlanELem'
              }, function (data, _this) {
                getBlocked()
              })
              getBlocked()
            }

          }

        }
      })
    }
  }).disableSelection();
  //$(".draggable").draggable('destroy')
  $(".draggable").draggable({
    connectToSortable: ".sortable",
    helper: "clone",
    revert: "invalid",
    stop: function (event, ui) {
      $(ui.helper).addClass("ui-helper");
    },
    start: function (event, ui) {
      $(ui.helper).removeClass("ui-helper");
    }
  }).disableSelection();

}

function getBlocked () {
  var _form = $('#formMitarbeiterPlanung')
  if(_form.length) {
    $.ajax({
      url: 'ajax.php',
      type: 'POST',
      dataType: 'json',
      data: {
        dateEnd: _form.attr('data-start-date'),
        dateStart: _form.attr('data-end-date'),
        modus: 'getSelcectionBlock',
        modulPfad: 'plugins/mod_planung/mitarbeiter/'
      },
      success: function (dataBack) {
        if (dataBack.block.fahrzeug) {
          $('.box.car li[data-modul=\'Fahrzeug\']').each(function () {
            if (jQuery.inArray($(this).attr('data-proid'), dataBack.block.fahrzeug) !== -1) {
              $(this).hide()
            } else {
              $(this).show()
            }
          })
        }
        if (dataBack.block.werkzeuge) {
          $('.box.tool li[data-modul=\'Werkzeuge\']').each(function () {
            if (jQuery.inArray($(this).attr('data-proid'), dataBack.block.werkzeuge) !== -1) {
              $(this).hide()
            } else {
              $(this).show()
            }
          })
        }
        if (dataBack.block.mitarbeiter) {
          $('.box.projekte li[data-modul=\'Projekt\']').each(function () {
            if (jQuery.inArray($(this).attr('data-proid'), dataBack.block.mitarbeiter) !== -1) {
              $(this).hide()
            } else {
              $(this).show()
            }
          })
        }
      }
    })
  }
}

function copyDay () {
  $('.copy-day').confirmation('destroy')
  $('.copy-day').confirmation({
    title: 'Soll der Datensatz auf den nächsten Tag kopiert werden?',
    placement: 'left',
    btnOkLabel: 'Ja',
    btnCancelLabel: 'Nein'
  }).on('confirmed.bs.confirmation', function (evt) {
    var _this = $(this)
    $.ajax({
      url: 'ajax.php',
      type: 'POST',
      dataType: 'json',
      data: {
        date: _this.attr('data-time'),
        proID: _this.attr('data-projekt'),
        modul: _this.attr('data-modul'),
        modus: 'copyDate',
        modulPfad: 'plugins/mod_planung/mitarbeiter/'
      },
      success: function (data) {
        if(data.link !== '') {
          window.location.href = data.link
          setTimeout(function(){window.location.reload(true);},500)
        }
         // console.log(data)
      }
    })
  })

  $('.copy-day-mit').confirmation('destroy')
  $('.copy-day-mit').confirmation({
    title: 'Soll der Datensatz auf den nächsten Tag kopiert werden?',
    placement: 'left',
    btnOkLabel: 'Ja',
    btnCancelLabel: 'Nein'
  }).on('confirmed.bs.confirmation', function (evt) {
    var _this = $(this)
    $.ajax({
      url: 'ajax.php',
      type: 'POST',
      dataType: 'json',
      data: {
        date: _this.attr('data-time'),
        usrID: _this.attr('data-user'),
        modul: _this.attr('data-modul'),
        modus: 'copyDateMit',
        modulPfad: 'plugins/mod_planung/mitarbeiter/'
      },
      success: function (data) {
        if(data.link !== '') {
          window.location.href = data.link
          setTimeout(function(){window.location.reload(true);},500)
        }
      }
    })
  })

  // $(document.body).on('click', '.free-file', function (evt) {
  //   var _this = $(this)
  //   $.ajax({
  //     url: 'ajax.php',
  //     type: 'POST',
  //     dataType: 'json',
  //     data: {
  //       uxDay: _this.attr('data-time'),
  //       uxPROID_FK: _this.attr('data-projekt'),
  //       modus: 'modalFree',
  //       modulPfad: 'plugins/mod_planung/bau/'
  //     },
  //     success: function (data) {
  //       if(data.link !== '') {
  //         window.location.href = data.link
  //         setTimeout(function(){window.location.reload(true);},500)
  //       }
  //     }
  //   })
  // })
}
export default function kdzMitarbeiterPlanung (settings) {
  kdzSubmit({
    element: '.submitPlan'
  }, function (data, _form) {
    $('li[data-uxid="' + data.contentID + '"]').html(data.html)
    kdzDeleteRow()
    var modal = _form.closest('.modal')
    modal.modal('hide')
    setTimeout(function () {
      modal.remove()
    }, 500);
  })

  initSortable()
  copyDay()
  kdzDeleteRow({
    el: '.deletePlanELem'
  }, function (data, _this) {
    getBlocked()
  })
  $(document.body).on('click', '#showAllProjects', function (evt) {
    var data = {
      checked: $(this).is(':checked'),
      modus: 'getProjects',
      modulPfad: 'plugins/mod_planung/mitarbeiter/'
    }
    $.ajax({
      url: 'ajax.php',
      type: 'POST',
      dataType: 'html',
      data: data,
      success: function (html) {
        $('#projekt-list').html(html)
        initSortable()
      }
    })
  })

  $(document).ready(function () {
    getBlocked()
  })
}