import $ from 'jquery';
import * as message from '../modules/message';
import kdzDeleteRow from './deleteRow';
import kdzModalLoader from './modal';
import kdzSubmit from './forms';
import vorlagenEditor from './vorlagenEditor';
import func from 'summernote/src/js/base/core/func';

let modulPfadAnfrage = 'plugins/mod_fibu/';
let modulPfadFibu = 'plugins/mod_fibu/';

function calculateItemPrices () {
    let fiID = $(this).attr('data-id');
    let typ = $(this).attr('data-typ');
    let itemCount = $('#fiCount-' + fiID);
    let priceSingle = $('#fiUnitBrutto-' + fiID);
    let rabatt = 0;
    if ($('#fiRabatt-' + fiID).length > 0) {
        rabatt = $('#fiRabatt-' + fiID).val();
    }

    let price = $('#fiBrutto-' + fiID);
    let modus = 'calculateItemPrices';
    let submitData = 'modus=' + modus +
      '&typ=' + typ +
      '&price=' + price.val() +
      '&itemCount=' + itemCount.val() +
      '&priceSingle=' + priceSingle.val() +
      '&rabatt=' + rabatt +
      '&modulPfad=' + modulPfadAnfrage;
    $.ajax({
        url: 'ajax.php',
        type: 'POST',
        dataType: 'json',
        data: submitData,
        success: function (data) {
            if (typ === 1 || typ === '1') {
                $('#fiBrutto-' + fiID).val(data['price']);
            } else if (typ === 3 || typ === '3') {
                $('#fiBrutto-' + fiID).val(data['price']);
            } else {
                $('#fiUnitBrutto-' + fiID).val(data['priceSingle']);
            }
            if ($('#rabattNoGesamt-' + fiID).length > 0) {
                $('#rabattNoGesamt-' + fiID).html(data['priceNoRabatt']);
            }
        }
    });
}

function changeStatus () {
    let fibuID = $(this).attr('data-id');
    let status = $(this).val();
    let modus = 'changeStatus';
    let submitData = 'modus=' + modus +
      '&fibuID=' + fibuID +
      '&status=' + status +
      '&modulPfad=' + modulPfadAnfrage;
    $.ajax({
        url: 'ajax.php',
        type: 'POST',
        dataType: 'json',
        data: submitData,
        success: function (data) {
            console.log(data);
        }
    });
}

function addItem () {
    let lists = $('.item-list');
    let notSaved = false;
    lists.each(function () {
        if ($(this).hasClass('not-saved')) {
            notSaved = true;
        }
    });
    //if (notSaved === false) {
    let fibuHash = $(this).attr('data-id');
    let simple = false;
    if (typeof $(this).attr('data-simple') !== 'undefined') {
        simple = true;
    }

    let modus = 'addItemList';
    $.ajax({
        url: 'ajax.php',
        type: 'POST',
        dataType: 'json',
        data: {
            'modus': modus,
            'fibuHash': fibuHash,
            'modulPfad': modulPfadAnfrage,
            'simple': simple,
            'noPrice': $(this).attr('data-noprice')
        },
        success: function (data) {
            // lists.each(function () {
            //   saveItemNoReload($(this))
            // })
            $('#item-list-body').append(data);
            initListeEvents();
            autosize(document.querySelectorAll('textarea.is--autosize'));
        }
    });
    // } else {
    //   message.message('Bitte Speichern Sie alle Rot markierten Artikel! Bevor sie einen neuen Artikel Hinzufügen', 'danger')
    // }
}

function setNotSaved () {
    let _this = $('#item-list-' + $(this).attr('data-id'));
    if (!_this.hasClass('not-saved')) {
        _this.addClass('not-saved');
    }
}

/*function saveItemNoReload(el){
  let _this = el
  //let lists = $('.item-list')
  let row = _this.attr('data-id')
  let fibuHash = _this.attr('data-fibuHash')
  let fiText = $('#fiText-' + row).val()
  let fiCount = $('#fiCount-' + row).val()
  let fiUnit = $('#fiUnit-' + row).val()
  //let fiVat = $('#fiVat-' + row).val()
  let fiUnitBrutto = $('#fiUnitBrutto-' + row).val()
  let fiBrutto = $('#fiBrutto-' + row).val()
  let fiID = $('#fiID-' + row).val()
  let fiArt = $('#fiArt-' + row).val()
  let fiRabatt = $('#fiRabatt-' + row).val()
  //if (fiText !== '') {
    $.ajax({
      url: 'ajax.php',
      type: 'POST',
      dataType: 'json',
      data: {
        'modus': 'saveItemNoReload',
        'fiText': fiText,
        'fibuHash': fibuHash,
        'fiRabatt': fiRabatt,
        'fiCount': fiCount,
        'fiUnit': fiUnit,
        'fiUnitBrutto': fiUnitBrutto,
        'fiBrutto': fiBrutto,
        'fiID': fiID,
        'fiArt': fiArt,
        'modulPfad': modulPfadAnfrage
      },
      success: function (data) {
        $('#fiID-' + row).val(data.fiID)
        $('item-list-' + row + ' .deleteItemMerk').attr('data-fiid', data.fiID)
      }
    })
}*/
function saveItem () {
    let _this = $(this);
    let lists = $('.item-list');
    let row = _this.attr('data-id');
    let fibuHash = _this.attr('data-fibuHash');
    let fiText = $('#fiText-' + row).val();
    let fiCount = $('#fiCount-' + row).val();
    let fiUnit = $('#fiUnit-' + row).val();
    let fiVat = $('#fiVat-' + row).val();
    let fiUnitBrutto = $('#fiUnitBrutto-' + row).val();
    let fiBrutto = $('#fiBrutto-' + row).val();
    let fiID = $('#fiID-' + row).val();
    let fiArt = $('#fiArt-' + row).val();
    let fiArtNumber = '';
    let fiRabatt = 0;
    let fiLineNumber = '';
    let fiTitel = '';
    if ($('#fiLineNumber-' + row).length > 0) {
        fiLineNumber = $('#fiLineNumber-' + row).val();
    }
    if ($('#fiTitel-' + row).length > 0) {
        fiTitel = $('#fiTitel-' + row).val();
    }
    if ($('#fiRabatt-' + row).length > 0) {
        fiRabatt = $('#fiRabatt-' + row).val();
    }
    if ($('#fiArtNumber-' + row).length > 0) {
        fiArtNumber = $('#fiArtNumber-' + row).val();
    }
    $.ajax({
        url: 'ajax.php',
        type: 'POST',
        dataType: 'json',
        data: {
            'modus': 'saveItem',
            'fiText': fiText,
            'fibuHash': fibuHash,
            'fiRabatt': fiRabatt,
            'fiCount': fiCount,
            'fiVat': fiVat,
            'fiLineNumber': fiLineNumber,
            'fiTitel': fiTitel,
            'fiUnit': fiUnit,
            'fiUnitBrutto': fiUnitBrutto,
            'fiBrutto': fiBrutto,
            'fiID': fiID,
            'fiArt': fiArt,
            'fiArtNumber': fiArtNumber,
            'modulPfad': modulPfadAnfrage
        },
        success: function (data) {
            if (data.error === false) {
                message.message('Der Eintrag wurde erfolgreich gespeichert', 'success');
                $('#item-list-' + row).removeClass('not-saved');
                setPricesGesamt();
            } else {
                message.message('Beim Speichern ist ein Fehler aufgetreten', 'danger');
            }
        }
    });
    /*if (fiText !== '') {

    } else {
        message.message('Bitte füllen Sie Namen  aus', 'danger');
    }*/
}

function deleteItem () {
    let _this = $(this);
    let row = _this.attr('data-id');
    let fiID = _this.attr('data-fiID');
    $(this).confirmation({
        title: 'Soll der Datensatz gelöscht werden?',
        placement: 'right',
        btnOkLabel: 'Ja',
        btnCancelLabel: 'Nein'
    }).on('confirmed.bs.confirmation', function (evt) {
        if (fiID !== '') {
            $.ajax({
                url: 'ajax.php',
                type: 'POST',
                dataType: 'json',
                data: {
                    'modus': 'deleteItem',
                    'fiID': fiID,
                    'modulPfad': modulPfadAnfrage
                },
                success: function (data) {
                    if (data.error === false) {
                        message.message('Der Eintrag wurde erfolgreich gelöscht', 'success');
                        $('#item-list-' + row).remove();
                        setPricesGesamt();
                    } else {
                        message.message('Beim Löschen ist ein Fehler aufgetreten', 'danger');
                    }
                }
            });
        } else {
            $('#item-list-' + row).remove();
            setPricesGesamt();
        }
    });
}

function setPricesGesamt () {
    let _fibuPriceNetto = $('#fibuPriceNetto');
    let _fibuPriceMwst = $('#fibuPriceMwst');
    let _fibuPriceBrutto = $('#fibuPriceBrutto');
    let _fibuVat = $('#fibuVat');
    let _fibuHash = $('#fibuHash');
    let _fibuRabattProzent = $('#fibuRabattProzent');
    if ($('#fibuGesamtFest').is(':checked')) {
        $.ajax({
            url: 'ajax.php',
            type: 'POST',
            dataType: 'json',
            data: {
                'modus': 'setPricesGesamtOnly',
                'fibuHash': _fibuHash.val(),
                'zwischensumme': $('#zwischensumme').val(),
                'fibuVat': _fibuVat.length > 0 ? _fibuVat.val() : 0,
                'fibuRabattProzent': _fibuRabattProzent.length > 0 ? _fibuRabattProzent.val() : 0,
                'modulPfad': modulPfadAnfrage,
                'fibuGesamtFest': $('#fibuGesamtFest').is(':checked')
            },
            success: function (data) {
                if (data.error === false) {
                    _fibuPriceNetto.val(data.fibuPriceNetto);
                    _fibuPriceBrutto.val(data.fibuPriceBrutto);
                    _fibuPriceMwst.val(data.fibuPriceMwst);
                    /*if ($('#zwischensumme').length > 0) {
                        $('#zwischensumme').val(data.zwischensumme);
                    }*/
                    $.each(window.mwst, function (key, value) {
                        var keyFormatted = parseFloat(value.replace(',', '.')).toFixed(3);
                        if (keyFormatted in data.mwst) {
                            $('#item-mwst-' + value).removeClass('hidden');
                            $('#fibuPriceMwst-' + value).val(parseFloat(data.mwst[keyFormatted]).toFixed(2).replace('.', ','));
                        } else {
                            $('#item-mwst-' + value).addClass('hidden');
                        }
                    });
                }
            }
        });
    } else {
        $.ajax({
            url: 'ajax.php',
            type: 'POST',
            dataType: 'json',
            data: {
                'modus': 'setPricesGesamt',
                'fibuHash': _fibuHash.val(),
                'fibuVat': _fibuVat.length > 0 ? _fibuVat.val() : 0,
                'fibuRabattProzent': _fibuRabattProzent.length > 0 ? _fibuRabattProzent.val() : 0,
                'modulPfad': modulPfadAnfrage
            },
            success: function (data) {
                if (data.error === false) {
                    _fibuPriceNetto.val(data.fibuPriceNetto);
                    _fibuPriceBrutto.val(data.fibuPriceBrutto);
                    _fibuPriceMwst.val(data.fibuPriceMwst);
                    if ($('#zwischensumme').length > 0) {
                        $('#zwischensumme').val(data.zwischensumme);
                    }
                    $.each(window.mwst, function (key, value) {
                        var keyFormatted = parseFloat(value.replace(',', '.')).toFixed(3);
                        if (keyFormatted in data.mwst) {
                            $('#item-mwst-' + value).removeClass('hidden');
                            $('#fibuPriceMwst-' + value).val(parseFloat(data.mwst[keyFormatted]).toFixed(2).replace('.', ','));
                        } else {
                            $('#item-mwst-' + value).addClass('hidden');
                        }
                    });
                }
            }
        });
    }

}

function getCustomerAdress () {
    let _cutomer = $(this);
    let cuID = _cutomer.val();
    $.ajax({
        url: 'ajax.php',
        type: 'POST',
        dataType: 'json',
        data: {
            'modus': 'getCustomerAdress',
            'cuID': cuID,
            'modulPfad': modulPfadAnfrage
        },
        success: function (data) {
            if (data.error === false) {
                $('#fibuAnschrift').val(data.adress);
                $('#customer-ap').html(data.apHtml).slideDown('slow');

                $('#fibuCustomerApID_FK').off();
                $('#fibuCustomerApID_FK').on('change', getCustomerApAdress);
            }
        }
    });
}

function getCustomerApAdress () {
    let _cutomerAP = $(this);
    let cuID = $('#fibuCustomerID_FK').val();
    let cuApID = _cutomerAP.val();
    $.ajax({
        url: 'ajax.php',
        type: 'POST',
        dataType: 'json',
        data: {
            'modus': 'getCustomerApAdress',
            'cuID': cuID,
            'cuApID': cuApID,
            'modulPfad': modulPfadAnfrage
        },
        success: function (data) {
            if (data.error === false) {
                $('#fibuAnschrift').val(data.adress);
            }
        }
    });
}

function refreshKunden (cuID) {
    let _cutomer = $('#fibu-kunden-body');
    $.ajax({
        url: 'ajax.php',
        type: 'POST',
        dataType: 'json',
        data: {
            'modus': 'refreshKunden',
            'cuID': cuID,
            'modulPfad': modulPfadAnfrage
        },
        success: function (data) {
            if (data.error === false) {
                _cutomer.html(data.html);
            }
        }
    });
}

function initKundenNew () {
    kdzModalLoader('.loadFibuKunden');
    kdzSubmit({
        element: '.submitFibuKunden'
    }, function (data, _form) {
        refreshKunden(data.contentID);
        var modal = _form.closest('.modal');
        modal.modal('hide');
        setTimeout(function () {
            modal.remove();
        }, 500);

    });
}

function initListeEvents () {
    // let _calaculate = $('.calculateItem');
    let _calaculateMain = $('.calculateMain');
    let _saved = $('.checkSaved');
    let _saveItem = $('.saveItem');
    // let _deleteItem = $('.deleteItemMerk');
    // _calaculate.off();
    _calaculateMain.off();
    _saved.off();
    _saveItem.off();
    // _deleteItem.off();
    _calaculateMain.on('input', setPricesGesamt);
    // _calaculate.on('input', calculateItemPrices);
    _saveItem.on('click', saveItem);
    // _deleteItem.on('click', deleteItem);
    _saved.on('input', setNotSaved);
    $(document.body).on('input', '.calculateItem', calculateItemPrices);
    $(document.body).on('input', '.deleteItemMerk', deleteItem);
    autosize(document.querySelectorAll('textarea.is--autosize'));

}

/*function loadVorlagenTexte ($typ, callback) {
  $.ajax({
    url: 'ajax.php',
    type: 'POST',
    dataType: 'json',
    data: {
      modus: 'loadVorlagenTexte',
      modulPfad: modulPfadFibu,
      typ: $typ
    },
    success: function (data) {
      callback(data)
    }
  })
}

function vorlagenText (context) {
  var ui = $.summernote.ui;
  // create button
  //console.log($(context.layoutInfo.note).closest('.box-body').attr('data-position'))

  var event = ui.buttonGroup([
    ui.button({
      contents: 'Textvorlagen <i class="fa fa-caret-down" aria-hidden="true"></i>',
      tooltip: 'Laden sie vorgerfertigte Texte in den Editor',
      data: {
        toggle: 'dropdown'
      }
    }),
    ui.dropdown({
      items: textTemplates,
      click: function (e) {
        var $button = $(e.target);
        var value = $button.data('value');
        e.preventDefault();
        loadVorlagenTexte(value, function (data) {
          context.layoutInfo.note.summernote('code', data.temText);
        })
      }
    })
  ]);

  return event.render();   // return button as jquery object
}

function initFibuEditor () {
  var editor = $('textarea.editor-fibu-text')
  editor.summernote({
    height: 300,
    minHeight: null,
    maxHeight: null,
    lang: 'de-DE',
    toolbar: [
      ['style', ['bold', 'italic', 'underline', 'clear']],
      ['font', ['strikethrough', 'superscript', 'subscript']],
      ['color', ['color']],
      ['fontsize', ['fontsize']],
      ['height', ['height']],
      ['para', ['ul', 'ol', 'paragraph']],
      ['insert', ['table']],
      ['fullscreen', ['fullscreen']],
      ['redo', ['redo']],
      ['undo', ['undo']],
      ['codeview', ['codeview']],
      ['eventButton', ['event']]
    ],
    fontSizes: ['8', '9', '10', '11', '12', '14', '16', '18', '24', '28', '30', '36'],
    colors: [
      ['#000000', '#424242', '#636363', '#9C9C94', '#CEC6CE', '#EFEFEF', '#F7F7F7', '#FFFFFF'],
      ['#FF0000', '#FF9C00', '#FFFF00', '#00FF00', '#00FFFF', '#0000FF', '#9C00FF', '#FF00FF'],
      ['#F7C6CE', '#FFE7CE', '#FFEFC6', '#D6EFD6', '#CEDEE7', '#CEE7F7', '#D6D6E7', '#E7D6DE'],
      ['#E79C9C', '#FFC69C', '#FFE79C', '#B5D6A5', '#A5C6CE', '#9CC6EF', '#B5A5D6', '#D6A5BD'],
      ['#E76363', '#F7AD6B', '#FFD663', '#94BD7B', '#73A5AD', '#6BADDE', '#8C7BC6', '#C67BA5'],
      ['#CE0000', '#E79439', '#EFC631', '#6BA54A', '#4A7B8C', '#3984C6', '#634AA5', '#A54A7B'],
      ['#9C0000', '#B56308', '#BD9400', '#397B21', '#104A5A', '#085294', '#311873', '#731842'],
      ['#630000', '#7B3900', '#846300', '#295218', '#083139', '#003163', '#21104A', '#4A1031']
    ],
    buttons: {
      event: vorlagenText
    }
  })
}*/

function saveFibuText () {
    let _this = $(this);
    let texID = _this.attr('data-id');
    let texFibuHash_FK = _this.attr('data-hash');
    let texPostion = _this.attr('data-position');
    let target = _this.attr('data-target');

    let texText = $('#' + target).val();
    $.ajax({
        url: 'ajax.php',
        type: 'POST',
        dataType: 'json',
        data: {
            modus: 'saveFibuText',
            modulPfad: modulPfadFibu,
            texID: texID,
            texFibuHash_FK: texFibuHash_FK,
            texPostion: texPostion,
            texText: texText
        },
        success: function (data) {
            if (data.error === true) {
                message.message('Beim Speichern ist ein Fehler aufgetreten, bitte versuchen Sie es noch einmal!', 'danger');
            } else {
                message.message('Der Inhalt wurde erfolgreich gespeichert!', 'success');
                _this.attr('data-id', data.contentID);
                $('[name=\'fibuTexte[' + texPostion + '][texID]\']').val(data.contentID);
            }
        }
    });
}

function selectArticleCatalog () {
    let _selectCatalog = $('#selectSubCatalog');
    let _selectArtikel = $('#addArtikelListValue');
    $.ajax({
        url: 'ajax.php',
        type: 'POST',
        dataType: 'json',
        data: {
            'catalog': _selectCatalog.val(),
            'modus': 'getArticleFromCatalog',
            'modulPfad': modulPfadAnfrage
        },
        success: function (data) {
            _selectArtikel.html(data.html).attr('disabled', null);
            _selectArtikel.selectpicker('refresh');
            $('#addArtikelList').attr('disabled', null);
        }
    });
}

function selectCatalog () {
    let _selectCatalog = $('#selectArticelCatalog');
    let _selectArtikel = $('#selectSubCatalog');
    $.ajax({
        url: 'ajax.php',
        type: 'POST',
        dataType: 'json',
        data: {
            'catalog': _selectCatalog.val(),
            'modus': 'getSubCatalog',
            'modulPfad': modulPfadAnfrage
        },
        success: function (data) {
            _selectArtikel.html(data.html).attr('disabled', null);
            _selectArtikel.selectpicker('refresh');

        }
    });
}

function addArticle () {
    let lists = $('.item-list');
    let notSaved = false;
    lists.each(function () {
        if ($(this).hasClass('not-saved')) {
            notSaved = true;
        }
    });
    let fibuHash = $(this).attr('data-id');
    let simple = false;

    if (typeof $(this).attr('data-simple') !== 'undefined') {
        simple = true;
    }
    console.log('addArticel', typeof $(this).attr('data-simple'));
    let artID = $('#addArtikelListValue').val();
    let modus = 'addArticleList';
    $.ajax({
        url: 'ajax.php',
        type: 'POST',
        dataType: 'json',
        data: {
            'artID': artID,
            'modus': modus,
            'fibuHash': fibuHash,
            'simple': simple,
            'modulPfad': modulPfadAnfrage
        },
        success: function (data) {
            $('#item-list-body').append(data);
            initListeEvents();
            autosize(document.querySelectorAll('textarea.is--autosize'));
            setPricesGesamt();
        }
    });
}

function addDienstleistung () {
    let lists = $('.item-list');
    let notSaved = false;
    lists.each(function () {
        if ($(this).hasClass('not-saved')) {
            notSaved = true;
        }
    });
    let fibuHash = $(this).attr('data-id');
    let dlID = $('#addDienListsleistungValue').val();
    let modus = 'addDienstleistungList';
    let simple = false;
    if (typeof $(this).attr('data-simple') !== 'undefined') {
        simple = true;
    }
    $.ajax({
        url: 'ajax.php',
        type: 'POST',
        dataType: 'json',
        data: {
            'dlID': dlID,
            'modus': modus,
            'fibuHash': fibuHash,
            'simple': simple,
            'modulPfad': modulPfadAnfrage
        },
        success: function (data) {
            $('#item-list-body').append(data);
            initListeEvents();
            autosize(document.querySelectorAll('textarea.is--autosize'));
            setPricesGesamt();
        }
    });
}

function addSubtileList () {
    let lists = $('.item-list');
    let notSaved = false;
    lists.each(function () {
        if ($(this).hasClass('not-saved')) {
            notSaved = true;
        }
    });
    //if (notSaved === false) {
    let fibuHash = $(this).attr('data-id');
    let simple = false;
    if (typeof $(this).attr('data-simple') !== 'undefined') {
        simple = true;
    }

    let modus = 'addSubTitelList';
    $.ajax({
        url: 'ajax.php',
        type: 'POST',
        dataType: 'json',
        data: {
            'modus': modus,
            'fibuHash': fibuHash,
            'modulPfad': modulPfadAnfrage,
            'simple': simple,
            'noPrice': $(this).attr('data-noprice')
        },
        success: function (data) {
            // lists.each(function () {
            //   saveItemNoReload($(this))
            // })
            $('#item-list-body').append(data);
            initListeEvents();
            autosize(document.querySelectorAll('textarea.is--autosize'));
        }
    });
}

function sort_tr (a, b) {
    return ($(b).find('.linenumber').val()) < ($(a).find('.linenumber').val()) ? 1 : -1;
}

function sortRows () {
    $('#item-list-body tr')
      .sort(sort_tr)
      .appendTo('#item-list-body');

}

export default function init () {
    $(document).ready(function () {
        initListeEvents();
        initKundenNew();
        vorlagenEditor();

        $(document.body).on('change', '.changeAngebotsStatus', changeStatus);
        // $('.changeAngebotsStatus').on('change', changeStatus)
        $('#addItemList').on('click', addItem);
        $('#addSubTitelList').on('click', addSubtileList);
        $('#addArtikelList').on('click', addArticle);
        $('#addDienListsleistung').on('click', addDienstleistung);
        $('#fibuCustomerID_FK').on('change', getCustomerAdress);

        let _ap = $('#fibuCustomerApID_FK');
        if (_ap.length) {
            _ap.off();
            _ap.on('change', getCustomerApAdress);
        }

        let _saveText = $('.saveFibuText');
        if (_saveText.length) {
            _saveText.off();
            _saveText.on('click', saveFibuText);
        }
        // $(document.body).on('change', '#selectArticelCatalog', selectCatalog)
        $('#selectArticelCatalog').on('changed.bs.select', selectCatalog);
        $('#selectSubCatalog').on('changed.bs.select', selectArticleCatalog);
        $(document.body).on('click', '#sortRows', sortRows);
        $(document.body).on('input', '[name="fibuSicherheitType"]', function (e) {
            let value = parseInt($(this).val());
            switch (value) {
                case 1:
                    $('#fibuSicherheitProzent').attr('readonly', null);
                    $('#fibuSicherheitWert').attr('readonly', 'readonly');
                    $('#fibuSicherheitJahre').attr('readonly', null);
                    $('#fibuSicherheitMonate').attr('readonly', null);
                    break;
                case 2:
                    $('#fibuSicherheitProzent').attr('readonly', 'readonly');
                    $('#fibuSicherheitWert').attr('readonly', null);
                    $('#fibuSicherheitJahre').attr('readonly', null);
                    $('#fibuSicherheitMonate').attr('readonly', null);
                    break;
                default:
                    $('#fibuSicherheitProzent').attr('readonly', 'readonly');
                    $('#fibuSicherheitWert').attr('readonly', 'readonly');
                    $('#fibuSicherheitJahre').attr('readonly', 'readonly');
                    $('#fibuSicherheitMonate').attr('readonly', 'readonly');
                    break;
            }
            console.log(typeof value, value);
        });
        $(document.body).on('input', '#zwischensumme', setPricesGesamt);
        $(document.body).on('input', '#fibuSicherheitProzent', function () {
            let gesamt = parseFloat($('#fibuPriceBrutto').val().replace('.', '').replace(',', '.'));
            let prozent = parseFloat($(this).val().replace('.', '').replace(',', '.'));
            var result = (prozent / 100) * gesamt;
            $('#fibuSicherheitWert').val(result.toFixed(2).replace('.', ','));
        });
    });
}
