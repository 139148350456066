import $ from 'jquery'

function countHours () {
  let _elems = $('.zeitenGesamt')
  let _gesamt = $('#wtHoursGesamt')
  let _gesamtOut = $('#wtHoursGesamt-out')
  let _overHours = $('#wtOverHours')
  let _overHoursOut = $('#wtOverHours-out')
  let hoursSoll = parseFloat($('#wtSoll').val())
  let hours = 0
  if(!_elems.is('[readonly]')) {
    _elems.each(function (index) {
      let val = $(this).val()
      if (val) {
        val = parseFloat(val.replace(',', '.').replace(' ', ''))
        if (!isNaN(val)) {
          hours = hours + val
        }
      }
    });
    _gesamt.val(hours)
    _gesamtOut.html(hours)
    let over = hours - hoursSoll
    if (over < 0) {
      over = 0
    }
    _overHours.val(over)
    _overHoursOut.html(over)
  }
}
function countAbloese () {
  let _elems = $('.ausloese-count')
  let _gesamt = $('#wtAusloeseGesamt')
  let gesamt = 0
  if(!_elems.is('[readonly]')){
    _elems.each(function( index ) {
      let val = $(this).val()
      if(val) {
        val = parseFloat(val.replace(',', '.').replace(' ', ''))
        if(!isNaN(val)) {
          gesamt = gesamt + val
        }
      }
    });
    _gesamt.val(gesamt)
  }
}

function calcHours () {
  let _this = $(this)
  let _parent = _this.closest('tr')
  let wtdTimeFrom = _parent.find('.wtdTimeFrom').val()
  let wtdTimeTo = _parent.find('.wtdTimeTo').val()
  let wtdPause = _parent.find('.wtdPause').val()
  let wtdDay = _parent.find('.wtdDay').val()
  if(!_parent.find('.wtdTimeFrom').is('[readonly]') && wtdTimeFrom !==''  && wtdTimeTo !=='') {
    var data = {
      wtdDay: wtdDay,
      wtdTimeFrom: wtdTimeFrom,
      wtdTimeTo: wtdTimeTo,
      wtdPause: wtdPause,
      modus: 'calcWorkingTime',
      modulPfad: 'plugins/mod_user/zeitenausgabe/'
    }
    $.ajax({
      url: 'ajax.php',
      type: 'POST',
      dataType: 'json',
      data: data,
      success: function (data) {
        _parent.find('.zeitenGesamt').val(data.time)
      }
    })
  }
}
export default function init () {
  $(document).ready(function () {
    $(document.body).on('blur keyup', '.ausloese-count', countAbloese)
    $(document.body).on('blur keyup', '.zeitenGesamt', countHours)

    $(document.body).on('change', '.wtdTimeFrom', calcHours)
    $(document.body).on('change', '.wtdTimeTo', calcHours)
    $(document.body).on('blur keyup', '.wtdPause', calcHours)
    countHours()
  })
}