import $ from 'jquery';
import kdzDeleteRow from './deleteRow';

function loadTableData (elem) {
    let _this = elem;
    let modulPfad = _this.attr('data-path');
    let menuID = _this.attr('data-menu-id');
    let modul = _this.attr('data-modul');
    let modus = 'loadTable';
    let lengthLabels = [];
    let defaultLengths = [10, 25, 50, 100, 500, -1];
    let tableLengths = defaultLengths;

    // Array aufbereiten
    $.each(tableLengths, function (index, value) {
        if (value !== -1 && value !== '-1') {
            lengthLabels[index] = value;
        } else {
            lengthLabels[index] = 'alle';
        }
        tableLengths[index] = parseInt(value);
    });
    _this.find('#dataTableAjax').dataTable({
        'lengthMenu': [tableLengths, lengthLabels],
        responsive: true,
        stateSave: true,
        'stateSaveParams': function (settings, data) {
            data.search.search = ''; // Suche nicht speichern
        },
        stateDuration: 0,
        language: {
            url: 'assets/plugins/datatables/jquery.dataTables.de.json'
        },
        'deferRender': true,
        'processing': true,
        'serverSide': true,
        'ajax': {
            'url': 'ajax.php',
            'type': 'POST',
            'dataType': 'json',
            'async': true,
            'data': {
                modus: modus,
                modulPfad: modulPfad,
                menuID: menuID,
                modul: modul
            }
        },
        'initComplete': function (settings, json) {
            kdzDeleteRow();
        },
        'drawCallback': function (settings) {
            kdzDeleteRow();
        }
    })
      .on('order.dt', function (evt, data) {
          kdzDeleteRow();
      })
      .on('search.dt', function (evt, data) {
          kdzDeleteRow();
      })
      .on('page.dt', function (evt, data) {
          kdzDeleteRow();
      });

    _this.find('[data-table-ajax]').dataTable({
        'lengthMenu': [tableLengths, lengthLabels],
        responsive: true,
        stateSave: true,
        'stateSaveParams': function (settings, data) {
            data.search.search = ''; // Suche nicht speichern
        },
        stateDuration: 0,
        language: {
            url: 'assets/plugins/datatables/jquery.dataTables.de.json'
        },
        'deferRender': true,
        'processing': true,
        'serverSide': true,
        'ajax': {
            'url': 'ajax.php',
            'type': 'POST',
            'dataType': 'json',
            'async': true,
            'data': {
                modus: modus,
                modulPfad: modulPfad,
                menuID: menuID,
                modul: modul
            }
        },
        'initComplete': function (settings, json) {
            kdzDeleteRow();
        },
        'drawCallback': function (settings) {
            kdzDeleteRow();
        }
    })
      .on('order.dt', function (evt, data) {
          kdzDeleteRow();
      })
      .on('search.dt', function (evt, data) {
          kdzDeleteRow();
      })
      .on('page.dt', function (evt, data) {
          kdzDeleteRow();
      });
}


function setTableLength () {
    let _elem = $('#dataTable_length select[name=dataTable_length]');
    if (_elem.length > 0 && $.cookie('data_table_length')) {
        var dataTableLength = $.cookie('data_table_length');
        _elem.val(dataTableLength);
        _elem.trigger('change');
    }
}

function saveTableLength () {
    var value = $(this).val();
    var domain = $('#httpHost').val();
    $.cookie('data_table_length', value, {domain: domain, path: '/'});
}

export default function init () {
    $(document).ready(function () {
        $('[data-load-ajax="true"]').each(function () {
            loadTableData($(this));
        });

        $(document.body).on('change', '#dataTable_length select[name=dataTable_length]', saveTableLength);

        /* Datatables */
        let lengthLabels = [];
        let defaultLengths = [10, 25, 50, 100, 500, -1];
        let tableLengths = defaultLengths;

        // Array aufbereiten
        $.each(tableLengths, function (index, value) {
            if (value !== -1 && value !== '-1') {
                lengthLabels[index] = value;
            } else {
                lengthLabels[index] = 'alle';
            }
            tableLengths[index] = parseInt(value);
        });

        $('[data-is-table="true"]').each(function () {
           $(this).dataTable({
             'lengthMenu': [tableLengths, lengthLabels],
             responsive: true,
             stateSave: true,
             'stateSaveParams': function (settings, data) {
               data.search.search = ''; // Suche nicht speichern
             },
             stateDuration: 0,
             language: {
               url: 'assets/plugins/datatables/jquery.dataTables.de.json'
             }
           });
        });
        $('#dataTable').dataTable({
            'lengthMenu': [tableLengths, lengthLabels],
            responsive: true,
            stateSave: true,
            'stateSaveParams': function (settings, data) {
                data.search.search = ''; // Suche nicht speichern
            },
            stateDuration: 0,
            language: {
                url: 'assets/plugins/datatables/jquery.dataTables.de.json'
            }
        });
        setTableLength();
    });
}
